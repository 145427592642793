<template>
  <section class="content-container">
      <SectionTabs :tabs="tab_component"/>
      <router-view :key="$route.fullPath"/>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import SectionTabs from "@/components/Section/SectionTabs.vue";

export default {
  components: { 
    SectionTabs,
  },
  data() {
    return {
      title: "Mural Digital",
      tab_component: [
        {
          name: "Dashboard",
          route: "CanalCorporativo-dashboard",
        },
        {
          name: "Canales",
          route: "CanalCorporativo-canales",
        },
        {
          name: "Pantallas",
          route: "CanalCorporativo-pantallas",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("sidebar", [ "userHasOnlyOneModule"]),
  }
};
</script>